import React from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import httpService from "services/http-service";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";

const DeleteWorkplaces = ({ headerTitle = "", buttonText = "" }) => {
  const navigate = useNavigate();
  const [workplaces, setWorkplaces] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const userWorkPlace = localStorage.getItem("workPlaceName");

  React.useEffect(() => {
    getAllWorkPlaces();
  }, []);

  const getAllWorkPlaces = async () => {
    setLoading(true);
    const endpoint = `/workplaces/getAll`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
      setWorkplaces([]);
      localStorage.setItem("isWorking", false);
      updateWorkStatus(false);
    } else {
      setLoading(false);
      setWorkplaces(response);
    }
  };

  const updateWorkStatus = async (workStatus) => {
    setLoading(true);
    const endpoint = `/bartenders/updateWorkStatus`;
    const data = { isWorking: workStatus };
    const response = await httpService("POST", endpoint, data);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
    }
  };

  const handleRemoveWorkplace = async (workplaceData) => {
    setLoading(true);
    const endpoint = `/workplaces/delete/${workplaceData.Id}`;
    const response = await httpService("DELETE", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
      toast.success(response?.Message || "Workplace removed successfully!");
      if (workplaceData.Workplace === userWorkPlace) {
        localStorage.setItem("workPlaceName", "");
      }
      getAllWorkPlaces();
    }
  };

  const handleRouteClick = (page) => {
    navigate(page);
  };

  return (
    <Card extra={"!mt-[2rem] mb-10 !z-5 "}>
      <Loading loading={loading} loaderColor="#f97316" />
      {/* DeleteWorkplaces Header */}
      <div className="ml-3 flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          {headerTitle}
        </div>
      </div>

      {/* History CardData */}
      {workplaces.length === 0 ? (
        <div className="pt-[100px] pb-[100px] text-center">
          <div classname="text-navy-700 dark:text-white">
            No workplaces found
          </div>
          <div classname="text-navy-700 dark:text-white">
            would you like to add one?
          </div>
        </div>
      ) : (
        <div className="list-scroll-d">
          {workplaces.map((data, index) => (
            <div
              key={index}
              className="flex w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700"
            >
              <div className="flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center">
                  <img
                    className="h-full w-full rounded-xl"
                    src={data.ImageURL}
                    alt=""
                  />
                </div>
                <div className="flex w-56 flex-col">
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                    {" "}
                    {data.Workplace}
                  </h5>
                  <p className="mt-1 text-sm font-normal text-gray-600">
                    {" "}
                    {data.Address}{" "}
                  </p>
                </div>
              </div>
              <div className="mt-1 ml-[-15px] flex items-center justify-center text-navy-700 dark:text-white">
                <div>
                  <button onClick={() => handleRemoveWorkplace(data)}>
                    <MdDeleteForever className="mt-1 h-6 w-6" />
                  </button>
                </div>
                <div className="ml-1 flex items-center text-sm font-bold text-navy-700 dark:text-white"></div>
                <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white"></div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex items-center justify-between">
        <button
          onClick={() =>
            handleRouteClick(workplaces.length === 0 ? "/auth/workplace" : 0)
          }
          className="linear w-90 m-4 mt-5  mb-5 w-full rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {" "}
          {workplaces.length === 0 ? "Add New Workplace" : buttonText}
        </button>
      </div>
    </Card>
  );
};

export default DeleteWorkplaces;
