export const getLocalStorageValue = (key, property) => {
  var localStorageItem = localStorage.getItem(key);

  // If the item does not exist in localStorage, return null
  if (!localStorageItem) {
    return null;
  }

  // Parse the localStorage item from a JSON string to a JavaScript object
  var localStorageObject = JSON.parse(localStorageItem);

  // Return the value associated with the property
  return localStorageObject[property];
};
