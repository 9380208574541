import api from "./api";

const httpService = async (method, endpoint, data = null, params = null) => {
  const config = {
    method,
    url: endpoint,
    data,
    params,
  };
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));
  if (userDetails) {
    config.headers = {
      Authorization:
        "Bearer " +
        localStorage.getItem("token"),
    };
  }

  try {
    const response = await api(config);
    const data = response?.data?.Data || response.data;
    return data;
  } catch (error) {
    return error.response?.data?.Data || error.response?.data;
  }
};

export default httpService;
