import { useNavigate } from "react-router-dom";
import logo from "assets/img/logo/logo.svg";

export default function Landing() {
  const navigate = useNavigate();

  const localIsWorking = localStorage.getItem("isWorking");
  if (!localIsWorking) {
    localStorage.setItem("isWorking", false);
  }

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[0vh] w-full max-w-full  flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mt-2 flex items-center justify-center text-6xl font-bold text-navy-700 dark:text-white">
          <img className="w-42 mt-2 h-20 " src={logo} alt="logo" />
        </h4>
        <h4 className="mt-5 flex items-center justify-center text-2xl font-bold text-navy-700 dark:text-white">
          See your regulars
        </h4>
        <div>
          <h4 className="mb-2.5 flex items-center justify-center text-2xl font-bold text-navy-700 dark:text-white">
            more regularly.
          </h4>
        </div>
        {/* Checkbox */}
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center"></div>
        </div>
        <div>
          <button
            onClick={() => handleRouteClick("/auth/sign-up")}
            className="linear mb-5 mt-20 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Sign Up
          </button>
          <button
            onClick={() => handleRouteClick("/auth/sign-in")}
            className="text-m mt-1 flex h-[50px] w-full items-center justify-center gap-2 rounded-full rounded-xl bg-lightPrimary text-xl font-medium text-navy-700 hover:cursor-pointer dark:bg-navy-800 dark:text-white"
          >
            Sign In
          </button>
          <div className="mt-4"></div>
        </div>
      </div>
    </div>
  );
}
