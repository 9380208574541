import Card from "components/card";
import httpService from "services/http-service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import QRCode from "react-qr-code";
import config from "utils/config";

export default function Qrcode({ bartenderName }) {
  const [loading, setLoading] = useState(false);
  const [QRCodeValue, setQRCodeValue] = useState("");

  const populateData = async () => {
    setLoading(true);
    const endpoint = `bartenders/getQRCodeId`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response?.Message);
    } else if (response) {
      setLoading(false);
      setQRCodeValue(
        config.INVITE_BASE_URL + `/auth/regular-sign-up/#${response}`
      );
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Card>
      <Loading loading={loading} loaderColor="#f97316" />

      <div className="mt-[2rem] flex h-full w-full items-center justify-center px-2 pt-[80px] pb-[80px] text-center md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
        {/* Sign in section */}
        <div className="mt-[0vh] w-full max-w-full  flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div className="text-center">
            <h2 className="text-center text-2xl font-bold text-navy-700 dark:text-white">
              {bartenderName ? "Scan to follow" : "Scan to join"}
            </h2>
          </div>

          {bartenderName && (
            <div className="mt-[1rem] text-center">
              <h2 className="text-center text-2xl font-bold text-navy-700 dark:text-white">
                {bartenderName}
              </h2>
            </div>
          )}

          {/* Checkbox */}
          <div className="mt-[2rem] flex items-center justify-center px-2">
            <div className="flex items-center">
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "15px",
                  width: "100%",
                }}
              >
                  <QRCode
                    size={180}
                    fgColor="orange"
                    value={
                      QRCodeValue ||
                      `${config.INVITE_BASE_URL}/auth/regular-sign-up`
                    }
                    viewBox={`0 0 256 256`}
                  />
              </div>
            </div>
          </div>
          <div className="mt-4">
            {/* <h4 className="text-0.1xl mt-7 flex items-center justify-center text-brand-500 text-navy-700 dark:text-white">
              Print and stick the code on the counter!
            </h4> */}
          </div>

          <div className="mt-4"></div>
        </div>
      </div>
    </Card>
  );
}
