import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import person from "assets/img/icons/person.png";

const FollowingsTable = (props) => {
  const { columnsData, title, rowsData, handleRemoveFollower } = props;
  const tableData = rowsData;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, page, prepareRow, initialState } =
    tableInstance;
  initialState.pageSize = 11;

  return (
    <div extra={"w-full h-full p-4"}>
      <div class="relative flex items-center justify-between">
        <div class="text-l font-bold text-navy-700 dark:text-white">
          {title}
        </div>
      </div>

      <div class="h-full xl:overflow-x-hidden ">
        <table
          {...getTableProps()}
          className="mt-0 h-max w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);

              return (
                <tr
                  className="flex justify-between"
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "NAME") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="align-center relative flex h-12 w-12 content-center items-center justify-center rounded-full bg-gray-100">
                            <img
                              className="h-5 w-5 text-brand-500 hover:text-brand-600 dark:text-white "
                              src={person}
                              alt="bookmark"
                            />
                            {title === "Working" && (
                              <div className="absolute right-0 top-0  ml-2 h-3 w-3 rounded-full bg-green-400 " />
                            )}
                          </div>
                          <p className="text-sm font-bold text-navy-700 dark:text-white w-40">
                            {cell.value}
                            <p className="text-sm font-normal text-gray-700 dark:text-white">
                              {cell?.row?.original?.LastWorkplace}
                            </p>
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Action") {
                      data = (
                        <div className="flex items-center justify-between gap-1 ">
                          <button
                            onClick={() => handleRemoveFollower(row.original)}
                            className="linear font-small w-25  ml-3 mt-0 rounded-sm border-orange-500 bg-brand-100 px-3 py-1 text-base font-bold text-orange-500 transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                          >
                            Unfollow
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-3 pb-3"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FollowingsTable;
