import Card from "components/card";
import httpService from "services/http-service";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdPeopleAlt } from "react-icons/md";
import { MdOutlinePerson } from "react-icons/md";
import GenericModal from "views/admin/default/components/GenericModal";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import QRCode from "react-qr-code";
import config from "utils/config";

const RegularsTable = (props) => {
  const { columnsData } = props;
  const [regulars, setRegulars] = useState([]);
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [QRCodeValue, setQRCodeValue] = useState("");

  const populateData = async () => {
    setLoading(true);
    const endpoint = `bartenders/getQRCodeId`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response?.Message);
    } else if (response) {
      setLoading(false);
      setQRCodeValue(
        config.INVITE_BASE_URL + `/auth/regular-sign-up/#${response}`
      );
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => regulars, [regulars]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const populateRegulars = async () => {
    setLoading(true);
    let bartenderId = JSON.parse(localStorage.getItem("userDetails")).userId;
    const endpoint = `/bartenders/getAllRegulars/${bartenderId}`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
      setRegulars([...response]);
      setTotalRegulars(response.length);
      if (response.length === 0) {
        populateData();
      }
    }
  };

  useEffect(() => {
    populateRegulars();
  }, []);

  const handleRemoveRegular = async (regularData) => {
    setLoading(true);
    const endpoint = `/bartenders/deleteRegularById/${regularData.Id}`;
    const response = await httpService("DELETE", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
      toast.success(response?.Message || "Regular deleted successfully!");
      const newRegulars = [...regulars];
      const index = regulars.indexOf(regularData);
      newRegulars.splice(index, 1);
      setRegulars(newRegulars);
    }
  };

  return (
    <Card extra={"w-full h-full p-4"}>
      <Loading loading={loading} loaderColor="#f97316" />
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Your Regulars
        </div>
        <h4 className="justify-left flex items-center text-base text-navy-700 dark:text-white">
          <MdPeopleAlt className="h-6 w-6" />
          <span className="ml-[5px]">{totalRegulars}</span>
        </h4>
        {/* <CardMenu /> */}
      </div>

      {regulars.length === 0 ? (
        <div className="pt-[100px] pb-[100px] text-center">
          <div classname="text-navy-700 dark:text-white">
            Share your code to
          </div>
          <div classname="text-navy-700 dark:text-white">
            invite your regulars
          </div>
          <div className="mt-[2rem] mr-20 flex items-center justify-center px-2">
            <div className="flex items-center">
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 100,
                  width: "100%",
                }}
              >
                <QRCode
                  size={180}
                  fgColor="orange"
                  value={
                    QRCodeValue ||
                    `${config.INVITE_BASE_URL}/auth/regular-sign-up`
                  }
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
          <table
            {...getTableProps()}
            className="mt-8 h-max w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "NAME") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              <MdOutlinePerson />
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "Action") {
                        data = (
                          <div className="flex items-center gap-1">
                            <GenericModal
                              modalTrigger={
                                <button className="linear border-1 font-small mt-0 w-20 rounded-sm border-orange-500 bg-brand-100 py-[1px] text-base text-orange-500 transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                  Remove
                                </button>
                              }
                              modalContent={
                                <Card extra={"w-full h-full p-4"}>
                                  <div className="relative flex items-center justify-between">
                                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                                      This can't be undone. Are you sure you
                                      want to continue?
                                    </div>
                                    <button
                                      onClick={() =>
                                        handleRemoveRegular(row.original)
                                      }
                                      className="linear border-1 font-small  mt-0 w-20 rounded-sm border-orange-500 bg-brand-100 py-[1px] text-base text-orange-500 transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </Card>
                              }
                            />
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-3 text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
};

export default RegularsTable;
