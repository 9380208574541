import React from "react";
import "./OfflineMode.css";
import sun from "assets/img/toggles/sun.png";
import moon from "assets/img/toggles/moon.png";
const OfflineMode = (props) => {
  const { onChange, isWorking } = props;

  return (
    <div className="dark_mode">

      <img
        className="h-12"
        src={!isWorking ? moon : sun}
        alt=""
        onClick={() => onChange(isWorking)}
      />
    </div>
  );
};

export default OfflineMode;
