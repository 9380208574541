import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/header/HeaderAuthDefault";
import httpService from "services/http-service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

const mask = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(mask);

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRegular, setIsRegular] = useState(false);
  const [phoneNumber, setphoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const followId = parseInt(window.location.hash.replace("#", ""));
  const [followName, setFollowName] = useState("");
  const [followLink, setFollowLink] = useState("");

  useEffect(() => {
    if (location.pathname.includes("regular")) {
      setIsRegular(true);
      if (followId.toString() !== "NaN") {
        localStorage.setItem("followId", followId);
        setFollowLink(followId);
        fetchBartenderName();
      }
    } else {
      setIsRegular(false);
    }
  }, [location.pathname]);

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (phoneNumber) {
      setLoading(true);
      const endpoint = isRegular ? "/regulars/login" : "/bartenders/login";
      const data = isRegular
        ? {
            phoneNumber: `+1${phoneNumber}`,
            referredByBartenderId: followId,
          }
        : {
            phoneNumber: `+1${phoneNumber}`,
          };

      const response = await httpService("POST", endpoint, data);
      if (response.Error === "HttpException") {
        setLoading(false);
        toast.error(response.Message);
      } else {
        if (response) {
          setLoading(false);
          localStorage.setItem("userDetails", JSON.stringify(response));
          toast.success("Gliddy SMS code has been sent");
          navigate("/auth/verify", {
            state: {
              isRegular: isRegular,
            },
          });
        }
      }
    }
  };

  const fetchBartenderName = async () => {
    setLoading(true);
    const endpoint = `/bartenders/getBartenderName/${followId}`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
    } else {
      setLoading(false);
      setFollowName(response);
    }
  };

  return (
    <div className="flex pt-6 h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#f97316" />
      {/* Sign in section */}
      <div className="mt-0 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <Header
          tagline={
            isRegular
              ? `Welcome back! ${followName && "You are following..."}`
              : "See your regulars more regularly"
          }
        />
        {followName && (
          <h4 className="mb-10 mt-[-30px] text-2xl font-bold text-navy-700 dark:text-white">
            {followName}
          </h4>
        )}
        <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="text-base font-medium text-navy-700 dark:text-white">
          We'll send a confirmation code to this number to verify your account.
        </p>
        {/* Phone */}
        <MaskedInput
          autoFocus
          className="dark:text-black mt-4 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10"
          placeholder="Phone Number"
          inputMode="numeric"
          pattern="\d*"
          onKeyDown={handleKeyDown}
          maskGenerator={maskGenerator}
          value={phoneNumber}
          onChange={(event) => setphoneNumber(`${event}`)}
        />

        <button
          disabled={phoneNumber.length !== 10}
          onClick={() => handleLogin()}
          className={
            phoneNumber.length === 10
              ? "linear mt-10 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear mt-10 w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
        >
          Send verification code
        </button>
        <h4 className="mt-5 items-center justify-center font-bold text-navy-700 dark:text-white">
          &nbsp; Don't have an account?{" "}
          <button
            className="text-brand-500"
            onClick={() =>
              handleRouteClick(
                isRegular
                  ? `/auth/regular-sign-up/#${followLink}`
                  : "/auth/sign-up"
              )
            }
          >
            {" "}
            &nbsp; Sign Up{" "}
          </button>
        </h4>
        <div className="signIn-notes mt-8">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
            We'll send you a verification code by SMS. Standard rates may apply.
            By continuing, I agree to Gliddy's
          </span>
          <a
            href="https://meetgliddy.com/terms-of-service"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Terms of Service.
          </a>
          <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-600">
            Please see Gliddy's
          </span>
          <a
            href="https://meetgliddy.com/privacypolicy"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Privacy Notice
          </a>
          <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-500">
            regarding its privacy practies.
          </span>
        </div>
      </div>
    </div>
  );
}
