import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getLocalStorageValue } from "utils/utils";
import { MdOutlineArrowBack } from "react-icons/md";
import GenericModal from "../admin/default/components/GenericModal";
import WorkplaceList from "../admin/default/components/WorkplaceList";
import DeleteWorkplaces from "../admin/default/components/DeleteWorkplaces";

export default function Settings({ ...props }) {
  const navigate = useNavigate();
  const home = localStorage.getItem("home");

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleLogout = (page) => {
    localStorage.clear();
    props.setIsAuthenticated(false);
    document.getElementById("main-body").classList.remove("dark");
    navigate("/auth/sign-in"); // Navigate to specific page
  };
  return (
    <div className="flex h-full w-full justify-center px-2 pt-6 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      {/* Settings Menu */}
      <div className="mt-[0vh] w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <button
          onClick={() => handleRouteClick(`${home}`)}
          className="absolute text-navy-700 dark:text-white"
        >
          <MdOutlineArrowBack className="mt-[4px] ml-[5px] h-8 w-8" />
        </button>
        <h4 className="align-center mb-[59px] mt-[5px] flex justify-center text-xl font-bold text-navy-700 dark:text-white">
          Settings
        </h4>

        <h4 className="flex items-center justify-center text-xl text-navy-700 dark:text-white">
          {getLocalStorageValue("userDetails", "userName") || "User"}
        </h4>
        <div className="mr-1 ml-1 flex-col items-center justify-center">
          <button
            onClick={() => handleRouteClick("/auth/edit-profile")}
            className="mt-[47px] flex w-full items-center justify-between text-base text-navy-700 dark:text-white"
          >
            Edit Profile{" "}
            <MdKeyboardArrowRight className="h-6 w-6 self-end text-gray-500" />
          </button>

          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <GenericModal
            modalTrigger={
              <button className="mt-5 flex w-full items-center justify-between text-base text-navy-700 dark:text-white">
                Add new workplace{" "}
                <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
              </button>
            }
            modalContent={
              <WorkplaceList
                type="'Find'"
                headerTitle="Find your workplace"
                buttonText="Add workplace"
              />
            }
          />
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <GenericModal
            modalTrigger={
              <button className="mt-5 flex w-full items-center justify-between text-base text-navy-700 dark:text-white">
                Delete a workplace{" "}
                <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
              </button>
            }
            modalContent={
              <DeleteWorkplaces
                type="'Find'"
                headerTitle="Delete a workplace"
                buttonText="Done"
              />
            }
          />
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <a href="https://1mr7h7lz5wt.typeform.com/to/kp3WjXj3" target="_blank" rel="noreferrer">
            <button
              className="mt-5 flex w-full items-center justify-between text-base text-navy-700 dark:text-white"
            >
              Share Feedback{" "}
              <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
            </button>
          </a>
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <a href="https://meetgliddy.com/privacypolicy" target="_blank" rel="noreferrer">
            <button
              className="mt-5 flex w-full items-center justify-between text-base text-navy-700 dark:text-white"
            >
              Privacy Policy{" "}
              <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
            </button>
          </a>
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <a href="https://meetgliddy.com/terms-of-service" target="_blank">
            <button
              className="mt-5 flex w-full items-center justify-between text-base text-navy-700 dark:text-white"
            >
              Terms and conditions
              <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
            </button>
          </a>
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <button
            onClick={() => handleLogout("")}
            className="mt-5 flex w-full items-center justify-between text-base text-brand-500"
          >
            Log Out
            <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
          </button>
        </div>
      </div>
    </div>
  );
}
