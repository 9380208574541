// Custom components
import React from "react";
import { MdSearch } from "react-icons/md";

function SearchField(props) {
  const {
    label,
    id,
    extra,
    type,
    value,
    placeholder,
    variant,
    state,
    disabled,
    maxLength = 1560,
    onChange,
    onKeyDown,
  } = props;

  return (
    <div
      className={`${extra} h-48px z-1001  group relative w-[90%]  items-center justify-center`}
    >
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-navy-700 ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <div className="inputWithButton">
        <input
          onKeyDown={onKeyDown}
          disabled={disabled}
          type={type}
          value={value}
          onChange={(event) => onChange(event)}
          id={id}
          placeholder={placeholder}
          maxLength={maxLength}
          className={`w-200 ju mt-2 flex h-12 w-full items-center justify-center rounded-xl border px-3 !pr-0 text-sm outline-none dark:text-navy-700 ${
            disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
        />
        <button>
          <MdSearch className="mt-1 mr-1 h-6 w-6" />
        </button>
      </div>
    </div>
  );
}

export default SearchField;
