import React, { useState } from "react";
import InputField from "components/fields/TextField";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";

export default function Feedback() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedBackText, setFeedBackText] = useState("");

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleSendFeedback = async () => {
    setLoading(true);
    const endpoint = "/feedback/add";
    const data = {
      Feedback: feedBackText,
    };
    const response = await httpService("POST", endpoint, data);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
      toast.success("Thanks for sharing your feedback");
      navigate("/auth/settings");
    }
  };

  return (
    <div className="mb-16 pt-6 flex h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#f97316" />
      {/* Feedback Section */}
      <div className="mt-[0vh] w-full max-w-full  flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
      <button
          onClick={() => handleRouteClick("/auth/settings")}
          className="absolute text-navy-700 dark:text-white"
        >
          <MdOutlineArrowBack className="mt-[4px] ml-[5px] h-8 w-8" />
        </button>
        <h4 className="align-center mb-[37px] mt-[5px] flex justify-center text-xl font-bold text-navy-700 dark:text-white">
          Send feedback
        </h4>

        <InputField
          variant="auth"
          extra="mt-[-30px] mb-[-20px] w-90"
          label=""
          id="feedback"
          placeholder="Share your valuable feedback"
          value={feedBackText}
          rows={10}
          cols={40}
          onChange={(e) => setFeedBackText(e.target.value)}
        />
        <button
          onClick={handleSendFeedback}
          className="linear mt-12 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Send feedback
        </button>
      </div>
    </div>
  );
}
