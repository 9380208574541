import React from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import SearchField from "components/fields/SearchField";
import httpService from "services/http-service";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import StateDropdown from "components/stateDropdown/StateDropdown";
import Checkbox from "components/checkbox";

const WorkplaceList = ({ type = "", headerTitle = "", buttonText = "" }) => {
  const navigate = useNavigate();
  const [barState, setBarState] = React.useState("Alabama");
  const [barName, setBarName] = React.useState("");
  const [workplaces, setWorkplaces] = React.useState([]);
  const [selectWorkplaces, setSelectWorkplaces] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleSelectWorkplace = (data, event) => {
    if (event.target.checked) {
      setSelectWorkplaces([...selectWorkplaces, data]);
    } else {
      const index = selectWorkplaces.findIndex(
        (x) => x.Address === data.Address && x.ImageURL === data.ImageURL
      );
      if (index > -1) {
        selectWorkplaces.splice(index, 1);
        setSelectWorkplaces([...selectWorkplaces]);
      }
    }
  };

  const handleSearchKeyUp = async (event) => {
    let checkDuplicate = false;
    if (barName !== "") {
      if (barName.length >= 3) {
        if (workplaces.length > 0) {
          workplaces.forEach((item) => {
            if (
              item.State.replace(/\s+/g, "").toLowerCase() ===
                barState.replace(/\s+/g, "").toLowerCase() &&
              item.Workplace.replace(/\s+/g, "").toLowerCase() ===
                barName.replace(/\s+/g, "").toLowerCase()
            ) {
              checkDuplicate = true;
            }
          });
        }
        if (!checkDuplicate) {
          if (barState && barName) {
            setLoading(true);
            const endpoint = `/workplaces/getByState/${barState}/${barName}`;
            const response = await httpService("GET", endpoint);
            if (response.Error === "HttpException") {
              setLoading(false);
              toast.error(response.Message);
            } else {
              setLoading(false);
              setWorkplaces([...workplaces, ...response]);
            }
          }
        }
      }
    } else {
      setWorkplaces([]);
    }

    setBarName("");
  };

  const handleDivClick = (index) => {
    const checkId = document.getElementById(`checkbox-${index}`);
    checkId.click();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchKeyUp();
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const endpoint = `/workplaces/saveAllWorkplaces`;
    const response = await httpService("POST", endpoint, selectWorkplaces);
    if (selectWorkplaces.length !== 0) {
      if (response.Error === "HttpException") {
        setLoading(false);
        toast.error(response.Message);
      } else {
        setLoading(false);
        navigate("/auth/workplace-home");
      }
    } else {
      setLoading(false);
      toast.warning("Please select workplace first");
    }
  };

  return (
    <Card extra={"!mt-[2rem] mb-10 !z-5 "}>
      <Loading loading={loading} loaderColor="#f97316" />
      {/* WorkplaceList Header */}
      <div className="ml-3 flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          {headerTitle}
        </div>
      </div>
      <div className="flex">
        <StateDropdown
          id="myStates"
          className="ml-[15px] mt-2 flex h-12 w-[93%] items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10 dark:text-navy-700"
          onChange={setBarState}
        />
      </div>
      <div className="flex">
        <SearchField
          variant="auth"
          extra="ml-4 dark:text-navy-700 w-[75%]"
          label=""
          placeholder="Enter workplace"
          type="text"
          value={barName}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setBarName(e.target.value);
            setWorkplaces([]);
          }}
        />
        <button
          type="submit"
          onClick={() => handleSearchKeyUp()}
          className="m-4 mt-8 mb-5  h-12 w-40  rounded-lg py-[12px] text-base  font-medium  text-orange-500 duration-200 dark:bg-navy-800 dark:text-orange-500 dark:hover:bg-navy-800 "
          style={{ borderWidth: 1, borderColor: "#f97316" }}
        >
          Search
        </button>
      </div>

      {/* Workplaces CardData */}
      <div className="list-scroll">
        {workplaces.map((data, index) => (
          <div
            key={index}
            className="flex w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700"
          >
            <div
              onClick={() => handleDivClick(index, data)}
              className="flex items-center gap-3"
            >
              <div className="flex h-16 w-16 items-center justify-center">
                <img
                  className="flex h-16 w-16 rounded-xl "
                  src={data.ImageURL}
                  alt=""
                />
              </div>

              <div className="flex w-56 flex-col">
                <h5 className="text-base font-bold text-navy-700 dark:text-white">
                  {" "}
                  {data.Workplace}
                </h5>
                <p className=" mt-1  text-sm font-normal text-gray-600">
                  {" "}
                  {data.Address}{" "}
                </p>
              </div>
            </div>
            <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
              <div>
                {" "}
                <Checkbox
                  id={`checkbox-${index}`}
                  onClick={(e) => handleSelectWorkplace(data, e)}
                />
              </div>
              <div className="ml-1 flex items-center text-sm font-bold text-navy-700 dark:text-white"></div>
              <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white"></div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between">
        <button
          onClick={() => handleSave()}
          disabled={selectWorkplaces.length === 0 ? true : false}
          className={
            selectWorkplaces.length !== 0
              ? "linear w-90 m-4 mt-5  mb-5 w-full rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear w-90 m-4 mt-5 mb-5 w-full  cursor-not-allowed rounded-lg bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
          }
        >
          {" "}
          {buttonText}
        </button>
      </div>
    </Card>
  );
};

export default WorkplaceList;
